$(document).ready(function () {
  swiperInit();

  $(".readmore-content").readmore({
    speed: 75,
    moreLink: '<a class="show-more-btn title-gradient-2" href="#">Xem thêm<span class="lnr lnr-chevron-down"></span></a>',
    lessLink: '<a class="show-more-btn title-gradient-2" href="#">Thu gọn<span class="lnr lnr-chevron-up"></span></a>',
    collapsedHeight: 72
  });

  UIkit.sticky('.about-nav', {
    offset: $('header').outerHeight()
  });


});

function swiperInit() {
  var bannerHomeSlider = new Swiper(".home-banner .swiper-container", {
    spaceBetween: 30,
    pagination: {
      el: ".home-banner .swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  });

  $(".service-gallery").each(function (index) {
    var $this = $(this);
    $this.find(".service-images").addClass("service-images-" + index);
    $this.find(".service-thumbnail").addClass("service-thumbnail-" + index);
    $this.find(".swiper-prev").addClass("services-swiper-prev-" + index);
    $this.find(".swiper-next").addClass("services-swiper-next-" + index);

    var serviceThumbnail = new Swiper(
      ".service-thumbnail-" + index + " .swiper-container",
      {
        slidesPerView: 6,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        breakpoints: {
          768.98: {
            slidesPerView: 4,
          },
        },
      }
    );

    var serviceImages = new Swiper(
      ".service-images-" + index + " .swiper-container",
      {
        slidesPerView: 1,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
        thumbs: {
          swiper: serviceThumbnail,
        },
        navigation: {
          prevEl: ".services-swiper-prev-" + index,
          nextEl: ".services-swiper-next-" + index,
        },
      }
    );
  });

  var home3Swiper = new Swiper(".home-3 .swiper-container", {
    slidesPerView: 2,
    initialSlide: 1,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    navigation: {
      prevEl: ".home-3 .swiper-prev",
      nextEl: ".home-3 .swiper-next",
    },
    breakpoints: {
      1600.98: {
        slidesPerView: 1,
      },
      1200.98: {
        slidesPerView: 1,
        centeredSlides: false,
      },
    },
  });

  var productThumbnail = new Swiper(".product-thumbnail .swiper-container", {
    slidesPerView: 6,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    breakpoints: {
      768.98: {
        slidesPerView: 4,
      },
    },
  });

  var productImages = new Swiper(".product-images .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    thumbs: {
      swiper: productThumbnail,
    },
    navigation: {
      prevEl: ".product-images .swiper-prev",
      nextEl: ".product-images .swiper-next",
    },
  });
}

const mainMenuMobileMapping = new MappingListener({
  selector: ".right-bottom-header",
  mobileWrapper: ".mobile-menu",
  mobileMethod: "appendTo",
  desktopWrapper: ".right-top-header",
  desktopMethod: "insertAfter",
  breakpoint: 1024.98,
}).watch();
